import { useReducer } from 'react';

import BuilderEditor from '../BuilderEditor';
import { getInitialBuilderState, builderReducer } from '../_builder_editor/lib';

export default function NewDynamicPage({ componentDefinitions, endpoints }) {
  const { indexUrl, createUrl, previewSource } = endpoints;

  const [state, dispatch] = useReducer(builderReducer, getInitialBuilderState());

  const handleSave = () => {
    fetch(`${createUrl}.json`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        credentials: 'same-origin',
      },
      body: JSON.stringify(state.data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to save page');
        }
        window.location.replace(indexUrl);
      })
      .catch((error) => {
        dispatch({ type: 'UPDATE', payload: { isError: true, error: JSON.stringify(error) } });
      });
  };

  return <BuilderEditor {...{ state, dispatch, handleSave, previewSource, componentDefinitions, indexUrl }} />;
}
