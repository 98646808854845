import { useEffect, useState, useRef } from 'react';
import ViewportOptions from './_builder_editor/ViewportOptions';
import { VIEWPORT_OPTIONS } from './_builder_editor/lib';

export default function BuilderEditor({ state, dispatch, handleSave, previewSource, componentDefinitions, indexUrl }) {
  const iFrameRef = useRef(null);
  const [viewport, setViewport] = useState(0);

  const handleLoad = () => {
    const content = JSON.stringify(state.data.content);
    iFrameRef.current.contentWindow.postMessage({ type: 'SET_CONTENT', message: content }, '*');
  };

  useEffect(() => {
    const iframe = iFrameRef.current;
    if (iframe) {
      iframe.addEventListener('load', handleLoad);
      handleLoad();
    }

    return () => {
      if (iframe) iframe.removeEventListener('load', handleLoad);
    };
  }, [state.data.content]);

  return (
    <div className="builder-editor">
      <div className="top-input">
        <a className="btn btn-primary" href={indexUrl}>
          Back
        </a>
        <ViewportOptions {...{ viewport, setViewport }} />
      </div>
      <div className={`iframe-preview ${VIEWPORT_OPTIONS[viewport].className}`}>
        <iframe ref={iFrameRef} src={previewSource} />
      </div>
    </div>
  );
}
