import React, { useState } from 'react';
import { SimpleTreeItemWrapper, SortableTree, flattenTree } from 'dnd-kit-sortable-tree';
import ConfirmationModal from '../confirmation-dialog/ConfirmationDialog';

/**
 * The function `updateCategories` is an asynchronous function that updates the order and ancestry of
 * category values based on the provided category ID, flattened items, and reason.
 * @param categoryID - The categoryID parameter is the ID of the category for which the category values
 * are being updated.
 * @param flattenedItems - An array of objects representing the category values. Each object should
 * have properties like `id`, `ancestry`, and `order`.
 * @param reason - The `reason` parameter is an object that contains information about the user action
 * from the dnd-kit-sortable-tree component.
 */
const updateCategories = async (categoryID, flattenedItems, reason) => {
  try {
    const url = `/_sf/api/v1/cms/categories/${categoryID}/category_values/update_all_order_and_ancestry.json`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        credentials: 'same-origin',
      },
      body: JSON.stringify({
        category_values: flattenedItems.map((each, index) => {
          if (each.id === reason.draggedItem.id) {
            return {
              ancestry: reason.droppedToParent?.id ?? null,
              id: each.id,
              order: index,
            };
          } else {
            return {
              id: each.id,
              order: index,
            };
          }
        }),
      }),
    });

    if (!response.ok) {
      throw new Error('Response NOT ok!');
    }
  } catch (error) {
    throw new Error('Error Caught:' + error);
  }
};

/**
 * The function filters an array of objects and their children based on a search term and an
 * identifier.
 * @param data - The `data` parameter is an array of objects that you want to filter.
 * @param search - The `search` parameter is a string that represents the value you want to search for
 * in the `identifier` property of each object in the `data` array.
 * @param identifier - The `identifier` parameter is a string that represents the attribute of each
 * object in the `data` array that will be used for filtering.
 * @returns The function `filterObjectsAndChildrenByIdentifier` returns a filtered array of objects.
 */
function filterObjectsAndChildrenByIdentifier(data, search) {
  const clonedData = structuredClone(data);

  return clonedData.filter((item) => {
    if (item.title.toLowerCase().includes(search.toLowerCase())) {
      // If the role does match, recursively filter its children
      if (item.children && item.children.length > 0) {
        item.children = filterObjectsAndChildrenByIdentifier(item.children, search);
      }

      return true; // Include this object in the result
    } else {
      // If the role does not match, recursively filter its children
      if (item.children && item.children.length > 0) {
        item.children = filterObjectsAndChildrenByIdentifier(item.children, search);
        return item.children.length > 0; // Include this object if its children match
      }

      return false; // Exclude this object
    }
  });
}

/**
 * The `treeSorter` function sorts an array of objects recursively based on a specified property name
 * and sorting mode.
 * @param items - The `items` parameter is an array of objects. Each object represents an item in a
 * tree structure. Each object can have a `children` property, which is also an array of objects
 * representing the children of that item. The objects also have a `schema_attributes` property, which
 * is an object
 * @param propertyName - The `propertyName` parameter is a string that represents the name of the
 * property in each item that you want to sort by.
 * @param mode - The "mode" parameter determines the sorting order of the items. It can have two
 * possible values: "ASC" for ascending order and "DESC" for descending order.
 */
const treeSorter = (items, propertyName, mode) =>
  items
    .map((item) => (item.children ? { ...item, children: treeSorter(item.children, propertyName, mode) } : item))
    .sort((i1, i2) => {
      if (mode === 'ASC') {
        return i1.schema_attributes[propertyName].toLowerCase().localeCompare(i2.schema_attributes[propertyName].toLowerCase());
      }

      if (mode === 'DESC') {
        return i2.schema_attributes[propertyName].toLowerCase().localeCompare(i1.schema_attributes[propertyName].toLowerCase());
      }
    });

export default function CategoryValues(props) {
  const { category, sorted_category_values, new_category_value_path } = props;

  /**
   * The Category Values to be used by the drag and drop (dnd-kit-sortable-tree) component.
   */
  const [categoryValues, setCategoryValues] = useState(sorted_category_values);

  /**
   * The Category Values copy that stores the original order of the category values, will be used as a reference point.
   */
  const [categoryValuesOriginalOrder, setCategoryValuesOriginalOrder] = useState(sorted_category_values);

  /**
   * The current sort mode of the drag and drop (dnd-kit-sortable-tree)
   * States are: "ASC", "DESC" and null (default)
   *
   * In null mode, the dnd-kit-sortable-tree component displays the custom order set by the user
   *  */
  const [sortMode, setSortMode] = useState(null);

  /**
   * A style object for the sort button.
   */
  const sortIconStyle = {
    transform: sortMode === 'DESC' ? 'rotate(180deg)' : '',
    transition: 'transform 200ms ease',
  };

  /**
   * The function handles sorting of category values in ascending, descending, or original order.
   */
  const handleSortOnClick = () => {
    //Clones the state for this function to run properly.
    const clone = structuredClone(categoryValues);

    //If the default mode of null is set, set it to "ASC" ascending order.
    if (sortMode === null) {
      setSortMode('ASC');
      setCategoryValues(treeSorter(clone, category.identifier, 'ASC'));
    }

    // If "ASC" is already set, set it to "DESC" descending order.
    if (sortMode === 'ASC') {
      setSortMode('DESC');
      setCategoryValues(treeSorter(clone, category.identifier, 'DESC'));
    }

    // If "DESC" is already set, set it default mode of null which shows the order set by user.
    if (sortMode === 'DESC') {
      setSortMode(null);
      setCategoryValues(categoryValuesOriginalOrder);
    }
  };

  /**
   * The function `handleOnItemsChanged` updates category values and calls `updateCategories` if the
   * reason for the change is "dropped".
   * @param items - The `items` parameter is an array that represents a tree structure. Each item in
   * the array can have a `children` property, which is also an array of items. The tree structure can
   * have multiple levels of nesting.
   * @param reason - The `reason` parameter is an object that describes the reason for the change in
   * items. It has a `type` property that indicates the type of change that occurred. In this case, the
   * code is checking if the `type` is equal to "dropped" to determine if the change was
   */
  const handleOnItemsChanged = (items, reason) => {
    setCategoryValues(items);
    setCategoryValuesOriginalOrder(items);
    const flattenedItems = flattenTree(items);

    if (reason.type === 'dropped') {
      updateCategories(category.id, flattenedItems, reason);
    }
  };

  /**
   * The function `handleSearchOnChange` filters and updates the category values based on the user's
   * search input.
   * @param event - The event parameter is an object that represents the event that triggered the
   * onChange event handler. In this case, it is likely an event object related to a change in the
   * input field value.
   * @returns The function does not explicitly return anything.
   */
  const handleSearchOnChange = (event) => {
    if (!event.target.value) {
      setCategoryValues(sorted_category_values);
      setCategoryValuesOriginalOrder(sorted_category_values);
      return;
    }

    const filteredData = filterObjectsAndChildrenByIdentifier(sorted_category_values, event.target.value, category.identifier);

    setCategoryValues(filteredData);
    setCategoryValuesOriginalOrder(filteredData);
  };

  return (
    <div className="nested-category-values-container">
      <div>
        <div className="input-group mb-3 w-25">
          <span className="input-group-text" id="search">
            <i className="fe fe-search" />
          </span>
          <input
            type="text"
            className="form-control"
            placeholder={`Search by ${category.identifier}...`}
            aria-label="Search"
            aria-describedby="search"
            onChange={handleSearchOnChange}
          />
        </div>
        <div>
          <button type="button" className="btn btn-outline-primary btn-sm sort-button" onClick={handleSortOnClick}>
            {!sortMode ? <i className="fe fe-minus" /> : <i className="fe fe-corner-right-down" style={sortIconStyle} />}
            <span>Sort</span>
          </button>
        </div>
      </div>
      {categoryValues && categoryValues.length ? (
        <SortableTree
          items={categoryValues}
          sortableProps={'order'}
          onItemsChanged={handleOnItemsChanged}
          TreeItemComponent={TreeItem}
          indentationWidth={24}
          category={category}
          disableSorting={category.locked_values}
        />
      ) : (
        <div className="no-values">
          <span>You do not have any values for this category.</span>
          <a href={new_category_value_path} className="btn btn-sm btn-primary" type="button" role="button">
            New value for {category.name}
          </a>
        </div>
      )}
    </div>
  );
}

const TreeItem = React.forwardRef((props, ref) => {
  const [isShowOptions, setIsShowOptions] = useState(false);

  /**
   * A style object for the show more options button.
   */
  const optionsButtonStyle = {
    transform: isShowOptions ? 'rotate(90deg)' : '',
    transition: 'transform 200ms ease',
  };

  /**
   * The function `handleDeleteOnClick` is an asynchronous function that sends a DELETE request to a
   * specified URL and reloads the page if the request is successful.
   * @param url - The `url` parameter is the URL of the resource that you want to delete.
   * @returns In the code provided, if the user confirms the deletion, the function will make a DELETE
   * request to the specified URL. If the response is not ok (status code other than 2xx), an error
   * will be thrown. If the response is ok, the window will be reloaded. If an error occurs during the
   * process, an error will be thrown.
   */
  const handleDeleteOnClick = async (url) => {
    try {
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          credentials: 'same-origin',
        },
      });

      if (!response.ok) {
        throw new Error('Response was not ok!');
      }

      window.location.reload();
    } catch (error) {
      throw new Error('Error Caught:' + error);
    }
  };

  return (
    <SimpleTreeItemWrapper {...props} ref={ref} className="category-value-sortable-card" disableCollapseOnItemClick>
      <div className="category-value-container">
        <div className="category-value-link-and-identifier">
          {props.category.has_template && (
            <a href={`/${[props.category.url_pattern, props.item.url_slug].join('/')}`.replace(/\/{2,}/, '/')} data-turbolinks="false" target="_blank">
              <i className="fe fe-external-link" />
            </a>
          )}
          {!props.category.locked_values ? (
            <a href={`category_values/${props.item.id}/edit`} data-turbolinks="false">
              {Array.isArray(props.item.title)
                ? `${props.item.title.length} Templates (${props.item.title
                    .map((each) => {
                      const { id, ...restOfEach } = each;

                      return Object.keys(restOfEach);
                    })
                    .reduce((acc, curr) => {
                      const matchingNode = acc.find((node) => node.productId === curr.productId);
                      if (!matchingNode) {
                        acc.push(curr);
                      }
                      return acc;
                    }, [])})`
                : props.item.title}
            </a>
          ) : (
            <span>
              {Array.isArray(props.item.title)
                ? `${props.item.title.length} Templates (${props.item.title
                    .map((each) => {
                      const { id, ...restOfEach } = each;

                      return Object.keys(restOfEach);
                    })
                    .reduce((acc, curr) => {
                      const matchingNode = acc.find((node) => node.productId === curr.productId);
                      if (!matchingNode) {
                        acc.push(curr);
                      }
                      return acc;
                    }, [])})`
                : props.item.title}
            </span>
          )}
          {props.item.is_draft && <span className="badge bg-secondary-soft">Draft</span>}
        </div>
        {!props.category.locked_values ? (
          <div className="category-value-controls" onMouseLeave={() => setIsShowOptions(false)}>
            <div className={`options ${isShowOptions && 'options-toggled'}`}>
              <a href={`category_values/${props.item.id}/edit`} className="btn btn-primary btn-sm" type="button">
                Edit
              </a>
              <button
                href={`category_values/${props.item.id}`}
                className="btn btn-danger btn-sm"
                data-bs-toggle="modal"
                data-bs-target={`#deleteConfirmationModal-${props.item.id}`}
              >
                Delete
              </button>
            </div>
            <button onClick={() => setIsShowOptions(!isShowOptions)} className="options-button" style={optionsButtonStyle}>
              <i className="fe fe-more-vertical" />
            </button>
          </div>
        ) : null}
        <ConfirmationModal
          id={`deleteConfirmationModal-${props.item.id}`}
          title="Confirm Deletion"
          message={`Are you sure you want to delete ${props.item.title ?? 'this category value'} ?`}
          confirmButtonText="Delete"
          onConfirm={() => handleDeleteOnClick(`/_sf/api/v1/cms/categories/${props.category.id}/category_values/${props.item.id}.json`)}
        />
      </div>
    </SimpleTreeItemWrapper>
  );
});
