import { VIEWPORT_OPTIONS } from './lib';

export default function ViewportOptions({ viewport, setViewport }) {
  const handleViewportChange = (event) => {
    setViewport(parseInt(event.target.value));
  };

  return (
    <div className="d-flex gap-2">
      {VIEWPORT_OPTIONS.map((option) => (
        <div key={option.id}>
          <input
            type="radio"
            className="btn-check"
            name="VIEWPORT_OPTIONS"
            id={option.name}
            autoComplete="off"
            value={option.id}
            checked={viewport === option.id}
            onChange={handleViewportChange}
          />
          <label className="btn" htmlFor={option.name}>
            <i className={option.icon} />
          </label>
        </div>
      ))}
    </div>
  );
}
