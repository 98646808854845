import { useState, useEffect } from 'react';
import { Tooltip } from 'bootstrap';

const SPLIT_INDEX = 5;

const STATUS_MAP = {
  is_from_sitemap: { text: 'Published', className: 'text-bg-success' },
  is_draft: { text: 'Draft', className: 'text-bg-secondary' },
  default: { text: 'Pending Build', className: 'text-bg-info' },
};

const initializeExpandedKeys = (pages, parentKey = '', level = 0, expandedKeys = {}) => {
  if (level >= 2) return expandedKeys; // Only expand up to level 2

  pages.forEach((page, index) => {
    const key = parentKey ? `${parentKey}-${index}` : `${index}`;
    expandedKeys[key] = true;
    if (page.children.length > 0) {
      initializeExpandedKeys(page.children, key, level + 1, expandedKeys);
    }
  });
  return expandedKeys;
};

function RowOptions({ page, endpoints, isSimple }) {
  const { currentDomain, editDynamicPageUrl, editCategoryValueUrl } = endpoints;

  const editCategoryValueLink = editCategoryValueUrl.replace(':category_id', page.parent_category_id).replace(':id', page.id);
  const editDynamicPageLink = editDynamicPageUrl.replace(':id', page.id);
  const isNavigable = page.is_from_sitemap;
  const isDynamicPage = page.is_dynamic_page;
  const editSiteManagerLink = `/_sf/admin/content/editor?path=/${page.url_slug}`;
  const editLink = page.parent_category_id ? editCategoryValueLink : isNavigable && !isDynamicPage ? editSiteManagerLink : editDynamicPageLink;

  if (isSimple) {
    return (
      <div className="dropdown">
        <button className="btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <i className="fe fe-more-horizontal" />
        </button>
        <ul className="dropdown-menu">
          {editLink && (
            <li>
              <a className="dropdown-item" target="_blank" rel="noopener noreferrer" href={editLink}>
                Edit
              </a>
            </li>
          )}
          {isNavigable && (
            <li>
              <a className="dropdown-item" target="_blank" rel="noopener noreferrer" href={`${currentDomain}${page.url_slug}`}>
                View
              </a>
            </li>
          )}
          <li>
            <hr className="dropdown-divider" />
          </li>
          <li className="d-flex align-items-center justify-content-center pb-2">
            <StatusBadge {...{ page }} />
          </li>
        </ul>
      </div>
    );
  }

  return (
    <div className="d-flex gap-2 align-items-center" style={{ width: 90 }}>
      <a className={`btn ${!editLink && 'disabled border-0'}`} target="_blank" rel="noopener noreferrer" href={editLink}>
        <i className={`fe fe-edit ${!editLink && 'invisible'}`} />
      </a>
      <a className={`btn ${!isNavigable && 'disabled border-0'}`} target="_blank" rel="noopener noreferrer" href={`${currentDomain}${page.url_slug}`}>
        <i className={`fe fe-external-link ${!isNavigable && 'invisible'}`} />
      </a>
    </div>
  );
}

function StatusBadge({ page }) {
  const status = page.is_from_sitemap ? STATUS_MAP.is_from_sitemap : page.is_draft ? STATUS_MAP.is_draft : STATUS_MAP.default;

  return <span className={`badge ${status.className}`}>{status.text}</span>;
}

function TreeLeaf({ page, endpoints, isSimple }) {
  return (
    <div key={page.id} className="d-flex flex-column">
      <div className="py-2 d-flex align-items-center justify-content-between">
        <div className="d-flex gap-3 align-items-center">
          <div className="d-flex flex-column justify-content-center">
            <span className="dp-page-title" data-bs-toggle="tooltip" data-bs-title={page.title}>
              <strong>{page.title}</strong>
            </span>
            <span className="dp-page-url-slug text-muted" data-bs-toggle="tooltip" data-bs-title={page.url_slug}>
              {page.url_slug}
            </span>
          </div>
        </div>
        {isSimple ? (
          <RowOptions {...{ page, endpoints, isSimple }} />
        ) : (
          <div className="d-flex gap-4 align-items-center">
            <StatusBadge {...{ page }} />
            <RowOptions {...{ page, endpoints }} />
          </div>
        )}
      </div>
    </div>
  );
}

function TreeLeaves({ pages, dispatch, endpoints, isSimple }) {
  const [isShowMore, setIsShowMore] = useState(false);

  const firstPart = pages.slice(0, SPLIT_INDEX);
  const secondPart = pages.slice(SPLIT_INDEX);

  {
  }

  return (
    <div className="d-flex flex-column ps-2">
      <div className="d-flex flex-column">
        {firstPart.map((page) => (
          <TreeLeaf key={page.id} {...{ page, endpoints, dispatch, isSimple }} />
        ))}
      </div>
      {isShowMore && secondPart.map((page) => <TreeLeaf key={page.id} {...{ page, endpoints, dispatch, isSimple }} />)}
      {secondPart.length > 0 && (
        <div className="d-flex flex-column align-items-center justify-content-center">
          <button className="btn text-muted" onClick={() => setIsShowMore((isShowMore) => !isShowMore)}>
            {isShowMore ? 'Show less' : `Show ${secondPart.length} more...`}
          </button>
        </div>
      )}
    </div>
  );
}

function TreeNode({ page, parentKey = '', index, toggleExpand, endpoints, dispatch, expandedKeys, isSimple }) {
  const currentKey = parentKey ? `${parentKey}-${index}` : `${index}`;
  const hasChildren = page.children.length > 0;
  const isExpanded = expandedKeys[currentKey];
  const allLeafNodes = page.children.length > 0 && page.children.every((leaf) => leaf.children.length === 0);

  return (
    <div className="d-flex flex-column ps-2">
      <div className="py-2 d-flex align-items-center justify-content-between">
        <div onClick={() => toggleExpand(currentKey)} className="d-flex gap-3 align-items-start" style={{ cursor: hasChildren && 'pointer' }}>
          {hasChildren && <i className={`text-muted fe ${isExpanded ? 'fe-minus-square' : 'fe-plus-square'}`} />}
          <div className="d-flex flex-column justify-content-center">
            {!page.isPlaceholder && (
              <span className="dp-page-title" data-bs-toggle="tooltip" data-bs-title={page.title}>
                <strong>{page.title}</strong>
              </span>
            )}
            <span className="dp-page-url-slug text-muted" data-bs-toggle="tooltip" data-bs-title={page.url_slug}>
              {page.url_slug}
            </span>
          </div>
        </div>
        {!page.isPlaceholder &&
          (isSimple ? (
            <RowOptions {...{ page, endpoints, isSimple }} />
          ) : (
            <div className="d-flex gap-4 align-items-center">
              <StatusBadge {...{ page }} />
              <RowOptions {...{ page, endpoints }} />
            </div>
          ))}
      </div>
      {isExpanded && (
        <div className="ps-5">
          {allLeafNodes ? (
            <TreeLeaves {...{ pages: page.children, parentKey: currentKey, expandedKeys, dispatch, endpoints, isSimple }} />
          ) : (
            hasChildren &&
            page.children.map((child, index) => (
              <TreeNode key={index} page={child} parentKey={`${currentKey}-${index}`} {...{ endpoints, dispatch, expandedKeys, toggleExpand, isSimple }} />
            ))
          )}
        </div>
      )}
    </div>
  );
}

function Tree({ pages, endpoints, dispatch, expandedKeys, toggleExpand, isSimple }) {
  return pages.map((page, index) => {
    return <TreeNode key={index} {...{ page, index, endpoints, dispatch, expandedKeys, toggleExpand, isSimple }} />;
  });
}

export default function SiteTree({ endpoints, state, dispatch, isSimple }) {
  const [expandedKeys, setExpandedKeys] = useState(initializeExpandedKeys(state.pages));

  const toggleExpand = (key) => {
    setExpandedKeys((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));

    // TODO, store in localStorage to persist across page refresh
  };

  useEffect(() => {
    const initializeTooltips = () => {
      const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
      [...tooltipTriggerList].forEach((tooltipTriggerEl) => {
        if (!Tooltip.getInstance(tooltipTriggerEl)) {
          new Tooltip(tooltipTriggerEl);
        }
      });
    };

    initializeTooltips();

    const observer = new MutationObserver(() => {
      initializeTooltips();
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
      [...tooltipTriggerList].forEach((tooltipTriggerEl) => Tooltip.getInstance(tooltipTriggerEl)?.dispose());
      observer.disconnect();
    };
  }, []);

  if (!state.pages.length) {
    return <div className="d-flex align-items-center justify-content-center text-muted pt-5">No pages found.</div>;
  }

  return (
    <div className="dp-site-tree-list">
      <Tree {...{ pages: state.pages, endpoints, dispatch, expandedKeys, toggleExpand, isSimple }} />
    </div>
  );
}
