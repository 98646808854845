export const builderReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE':
      return { ...state, ...action.payload };
    case 'UPDATE_DATA':
      return { ...state, data: { ...state.data, ...action.payload } };
    case 'UPDATE_INDIVIDUAL_CONTENT':
      return { ...state, data: { ...state.data, content: state.data.content.map((item) => (item.id === action.payload.id ? action.payload : item)) } };
    case 'ADD_CONTENT':
      return { ...state, data: { ...state.data, content: [...state.data.content, action.payload] } };
    case 'REMOVE_CONTENT':
      return { ...state, data: { ...state.data, content: state.data.content.filter((item) => item.id !== action.payload.id) } };
    default:
      return state;
  }
};

export const getInitialBuilderState = (dynamicPage) => {
  return {
    isLoading: false,
    isError: false,
    status: '',
    error: null,
    data: dynamicPage || {
      title: '',
      metadata: {},
      content: [],
      url_slug: '',
    },
  };
};

export const VIEWPORT_OPTIONS = [
  { id: 0, name: 'Desktop', className: 'desktop', icon: 'fe fe-monitor' },
  { id: 1, name: 'Tablet', className: 'tablet', icon: 'fe fe-tablet' },
  { id: 2, name: 'Mobile', className: 'mobile', icon: 'fe fe-smartphone' },
];
